<template>
  <div class="content">
    <Nav></Nav>
    <Navbj></Navbj>

    <div class="cont_inform">
      <div @click="$router.back()"> <i class="el-icon-arrow-left"></i>
        <span v-if="type == 1">
           返回&nbsp;&nbsp;冥想资讯
        </span>
       <span v-else>
          返回&nbsp;&nbsp;官方活动
       </span>

      </div>
    </div>

    <div class="cont_policy">
      <div class="policy_left">
        <div class="cont_title">{{list.title}}</div>
        <div class="cont_time">{{list.addtime | formatDateAt}}</div>
        <div class="cont_fx">
          <img @click="qqkjfx" src="https://qiniu.fanyu889.com/cxmx/assets/ydimg/fx_wx.png" />
          <img @click="qqfx" src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/qqfx.png"/>
          <share  :config="config"></share>
          <img @click="wbfx" src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/45.png"/>
        </div>

        <div class="cont_info" v-html="list.cont"></div>
        <div class="left_xgwz" v-if="xglist.length > 0">
           <p class="xgwz_title">相关文章</p>
          <div class="left_div">
              <div v-for="(item,index) in xglist" :key="index" @click="xg_Btn(item)">
                <img :src="item.img" alt="">
                <p>{{item.info}}</p>
              </div>

          </div>
        </div>
      </div>
      <div class="policy_right" >
          <div class="right_gzh">
            <img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/logo.png" alt="">
            <p>微信公众号</p>
            <img :src="user.gzhewm" alt="">
          </div>
          <div class="right_rswz" v-if="rdlist.length > 0">
            <p class="rswz_title">热点文章</p>
            <div @click="xg_Btn(item)" v-for="(item,index) in rdlist" :key="index"><span :style="{ backgroundColor: index === 0 ? '#FF3A3A' : (index === 1 ? '#FF9920' : (index === 2 ? '#D5A22A' : '#FFFFFF')),color:index < 3 ? '#FFFFFF':'#555555' }">{{index+1}}</span>{{item.title }}</div>

          </div>
      </div>

    </div>


    <Btm></Btm>
  </div>
</template>

<script>
import Nav from "@/components/pc-Nav/index"
import Navbj from "@/components/pc-Nav/nav_bj"
import Btm from "@/components/pc-Btmfooter/index"
import {mes_classify_list_xq, gfhd_list_xq, mes_wzlist, gfhd_list} from "@/api/request/all";
import Vue from 'vue'
import Share from 'vue-social-share'
import 'vue-social-share/dist/client.css';
import {mapState} from "vuex";
Vue.use(Share)

export default {
  name: "mesdetail",
  data(){
    return{
      id:this.$route.params.id,
      type:this.$route.params.type,
      list:'',
      config: {
        // url: "", // 网址，默认使用 window.location.href
        source: "", // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        title: "", // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
        description: "", // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
        image: "", // 图片, 默认取网页中第一个img标签
        sites: [
          "wechat",
        ], // 启用的站点
        // disabled: ["google", "facebook", "twitter"], // 禁用的站点
        wechatQrcodeTitle: "微信扫一扫：分享", // 微信二维码提示文字
        wechatQrcodeHelper:
            "<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>",
      },
      rdlist:[],
      xglist:[],
      jj:undefined
    }
  },
  components:{
    Nav,
    Navbj,
    Btm
  },
  computed: {
    ...mapState(['user']),
  },
  metaInfo() {
    return {
      title: this.list.title+'-流静冥想',
      meta: [
        { name: 'description', content: this.user.description},
        { name: 'keywords', content: this.user.seo}
      ]
    };
  },
  mounted() {
    this.xqsj();
    this.$router.afterEach((to,from,next)=>{
      window.scrollTo(0,0)
    })
  },
  watch:{
    $route(){
      this.xqsj(1)
    }
  },

  methods:{
    qqkjfx(){
      window.open(`https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${encodeURIComponent(window.location.href)}&sharesource=qzone&title=${encodeURIComponent(this.list.title)}&pics=${this.list.img}&summary=${encodeURIComponent(this.list.info)}`)
    },
    qqfx(){
     window.open('https://connect.qq.com/widget/shareqq/index.html?url=' + encodeURIComponent(window.location.href) + '&title=&pics=&summary=&desc=')
   },
    wbfx(){
     let url = encodeURIComponent(window.location.href)
     let title = encodeURIComponent(this.list.title)
      window.open(`https://service.weibo.com/share/share.php?url=${url}&title=${title}&pic=&appkey=&sudaref=`)
    },
    xqsj(val){
        if (val == 1){
          var list ={id:this.$route.params.id.split('.')[0]}
        }else {
          var list ={id:this.id.split('.')[0]}
        }
       if (this.type == 1){
         mes_classify_list_xq(list).then((res)=>{
           if (res.status == 1){
             this.list =res.data;
             this.rd_list();
             this.xg_list();
           }else {
             this.list =''
           }
         })
       }else{
         gfhd_list_xq(list).then((res)=>{
           if (res.status == 1){
             this.list =res.data;
             this.rd_list();
             this.xg_list();
           }else {
             this.list =''
           }
         })
       }
     },
    rd_list(){

      if (this.type == 1){
        let list ={
          page:1,
          pagenum:10,
          type:this.list.type,
          px:1
        }
        mes_wzlist(list).then((res)=>{
          if (res.status == 1){
            this.rdlist =res.data
          }
        })

      }else {
        let list ={
          page:1,
          pagenum:10,
          px:1
        }
        gfhd_list(list).then((res)=>{
          if (res.status == 1){
            this.rdlist =res.data
          }
        })
      }





    },
    xg_list(){

      if (this.type == 1){
        let list ={
          page:1,
          pagenum:8,
          type:this.list.type,
        }
        mes_wzlist(list).then((res)=>{
          if (res.status == 1){
            this.xglist =res.data
          }
        })
      }else {
        let list ={
          page:1,
          pagenum:8,
        }
        gfhd_list(list).then((res)=>{
          if (res.status == 1){
            this.xglist =res.data
          }
        })
      }



    },
    xg_Btn(item){
      this.$router.push({
        path:`/${this.type}/${item.id}.html`,
      })
    }

  }
}
</script>
<style>
.cont_info span:has(img){
 width:100%;
}
.cont_info img{
  max-width: 871px;
  height: auto!important;
  display: block;
  margin: 0 auto;
}
</style>
<style scoped lang="scss">
.content{
  img{
    display: block;
    margin: 0 auto;
  }
  .cont_inform{
    width: 100%;
    height: 51px;
    background: #EEEEEE;
    div{
      width: 1243px;
      margin:0 auto;
      height: 51px;
      line-height: 51px;

      font-weight: 500;
      font-size: 18px;
      color: #666666;

    }
  }
  .cont_policy{
    background: #FFFFFF;
    padding: 30px 0 66px;
    width: 1260px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    .policy_left {
      .cont_title {
        font-weight: 800;
        font-size: 32px;
        color: #333333;
        line-height: 48px;
        text-align: center;
      }
      .cont_time {
        font-weight: 500;
        font-size: 16px;
        color: #999999;
        line-height: 40px;
        text-align: center;
      }
      .cont_fx{
        width: 200px;
        height: 44px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        img{
          width: 44px;
          height: 44px;
        }
      }

      .cont_info {
        width: 871px;
        margin: 20px auto;

        .jz {
          display: block;
          margin: 0 auto;
        }

      }

      .about_xz {
        width: 300px;
        height: 66px;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .left_xgwz{
        margin-top: 100px;
        .xgwz_title {
          font-weight: 800;
          font-size: 24px;
          color: #333333;
          width: 100%;
        }
        .xgwz_title::before {
          content: '\2022'; /* 这是Unicode字符的小圆点 */
          color: #FFC13B;
          margin-right: 5px; /* 调整与文本之间的间距 */
        }
        .left_div{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 27px;
          div{
            width: 205px;
            border-radius: 10px;
            margin-bottom: 34px;
            img{
              width: 205px;
              height: 154px;
              border-radius: 10px;
            }
            p{
              font-weight: 500;
              font-size: 18px;
              color: #333333;
              line-height: 24px;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              word-wrap: break-word;
              white-space: normal;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              margin-top: 16px;
            }
          }

        }
      }
    }
    .policy_right{
      width: 308px;
      .right_gzh{
        width: 308px;
        height: 394px;
        background: #F4F4F4;
        border-radius: 10px;
        text-align: center;
        padding-top: 1px;
        img:nth-child(1){
          width: 144px;
          height: 46px;
          margin-top: 33px;
        }
        p:nth-child(2){
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          line-height: 80px;
        }
        img:nth-child(3){
          width: 190px;
          height: 190px;
          border-radius: 9px;
        }
      }
      .right_rswz{
        width: 268px;
        background: #F4F4F4;
        border-radius: 10px;
        margin-top: 57px;
        padding:0 20px 0px 20px;
        .rswz_title{
          width: 268px;
          border-bottom: 1px solid #E1E1E1;
          line-height: 59px;
          font-size: 24px;
          font-weight: 800;
          color: #333333;
        }
        .rswz_title::before{
          content: '\2022'; /* 这是Unicode字符的小圆点 */
          color: #FFC13B;
          margin-right: 5px; /* 调整与文本之间的间距 */
        }
        div{
          width: 100%;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          line-height: 48px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          white-space: normal;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          span{
            display: inline-block;
            width: 22px;
            height: 22px;
            background: #FF3A3A;
            border-radius: 5px;
            text-align: center;
            line-height: 22px;
            font-weight: bold;
            font-size: 16px;
            color: #FFFFFF;
            margin-right: 11px;
          }
        }
      }

    }


  }

}
</style>